import React, {useCallback, useMemo, useState, ReactNode, useEffect, ReactElement} from 'react';
import moment, {Moment} from 'moment-timezone';
import styles from './styles/calendar.module.scss';
import classNames from 'classnames';
import FlatIcon from "../FlatIcon";
import Event from "./Event";
import {CSSTransition} from "react-transition-group";
import FontAwesome from "../FontAwesome";
import {useTranslation} from "react-i18next";
import i18n from '../../../../components/internationalization/i18n'
import {IReactiveEventContentItem} from "../../../stores/SchoolFeedStore";
import viewMoreButton from "../../blocks/TerracedGridTheme/ViewMoreButton";

const weekdaysShort = moment.weekdaysShort();

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

export const currentMonthFormat = "MMM-YYYY";

export default function Calendar(props: {
    events: IReactiveEventContentItem[],
    onRangeChange: (range: { start: Moment, end: Moment }) => void,
    onEventClick: (event: IReactiveEventContentItem,
                   handleDelete?: (c: IReactiveEventContentItem) => void) => void,
    aboveTheFold: boolean,
    expandedView?: boolean,
    loading?: boolean,
    handleDeleteEvent: (contentItem: ContentItem) => void,
    initialMonth?: string,
    viewMoreButton?: ReactElement,
}) {
    // tracks the current calendar view - separate from currentDate because changing the month shouldn't also change the currently selected day
    const [currentMonth, setCurrentMonth] = useState<string>(props.initialMonth || moment().format(currentMonthFormat));
    // tracks the currently selected day
    const [currentDate, setCurrentDate] = useState<Moment>(moment());

    useEffect(() => {
        const firstEventOnCurrentDate: HTMLLIElement | null = document.querySelector(`#events-container li[data-start-date="${currentDate.format("MMM-D-YYYY")}"]`);
        if (firstEventOnCurrentDate) {
            const scrollableList = document.querySelector("#events-container");
            if (scrollableList) {
                scrollableList.scroll({
                    top: firstEventOnCurrentDate.offsetTop,
                    behavior: "smooth",
                })
            }
        }
    }, [currentDate, props.aboveTheFold, props.events.length])

    const { t } = useTranslation('translation', {i18n});

    const daysInMonth = useMemo(() => {
        const days: ReactNode[] = [];
        const currentMonthMoment = moment(currentMonth, currentMonthFormat);
        for (let i = 1; i <= currentMonthMoment.daysInMonth(); i++) {
            const todayMoment = moment(); // currentDate is just the currently selected date, not necessarily the actual today
            const currentDayString = `${i}-${currentMonth}`;
            const isToday = currentDayString === todayMoment.format("D-MMM-YYYY");

            const events = props.events.filter(event => {
                const start = moment(event.json_data.event.start);
                const end = moment(event.json_data.event.end);
                // all day events end at midnight the next day, so the end day is excluded from the isBetween check
                const inclusivity = event.json_data.event.all_day ? '[)' : '[]';
                return moment(`${i}-${currentMonthMoment.format("M-YYYY")}`, "D-M-YYYY").isBetween(start, end, "days", inclusivity);
            })

            const dayClassName = classNames({
                [styles.day]: true,
                [styles.dayToday]: isToday,
                [styles.daySelected]: currentDate.isSame(moment(currentDayString, "D-MMM-YYYY"), 'day'),
                [styles.dayPopulated]: events.length > 0,
            })

            days.push(<td key={currentDayString} className={dayClassName}>
                <button className={"notranslate"} onClick={() => {
                    setCurrentDate(() => moment(currentDayString, "D-MMM-YYYY"))
                }}>{i}</button>
            </td>)
        }
        return days;

    }, [currentDate, props.events]);


    const rows = useMemo(() => {
        const firstDay = moment(currentMonth, currentMonthFormat)
            .startOf("month")
            .format("d");

        const blanks: ReactNode[] = [];
        for (let i = 0; i < Number(firstDay); i++) {
            blanks.push(<td key={i}/>)
        }

        const totalSlots = [...blanks, ...daysInMonth];
        const r: ReactNode[] = [];
        let c: ReactNode[] = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                c.push(row); // if index not equal 7 that means not go to next week
            } else {
                r.push(c); // when reach next week we contain all td in last week to rows
                c = []; // empty container
                c.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                r.push(c);
            }
        });

        return r;
    }, [daysInMonth])

    const prevMonth = useCallback(() => {
        setCurrentMonth(v => {
            const newMoment = moment(v, currentMonthFormat).subtract(1, 'months');
            props.onRangeChange({
                start: moment(newMoment).startOf('month'),
                end: moment(newMoment).add(1, 'months').startOf('month'),
            })
            return newMoment.format(currentMonthFormat);
        });
    }, []);

    const nextMonth = useCallback(() => {
        setCurrentMonth(v => {
            const newMoment = moment(v, currentMonthFormat).add(1, 'months');
            props.onRangeChange({
                start: moment(newMoment).startOf('month'),
                end: moment(newMoment).add(1, 'months').startOf('month'),
            })
            return newMoment.format(currentMonthFormat);
        });
    }, []);

    const contentContainerClassName = classNames({
        [styles.contentContainer]: true,
    });
    const contentTransitionClassName = classNames({
        [styles.contentTransition]: true,
        [styles.contentTransitionHeightConstrained]: !props.expandedView,
    })

    let content;
    if (props.loading) {
        content = <div>
            <FontAwesome prefix={'fas'} name={'fa-spinner'} spin/>
        </div>
    } else if (props.events.length === 0) {
        content = <div className={styles.dayEvent} style={{textAlign: "center", padding: "1rem"}}>
            <span>No events for {moment(currentMonth, currentMonthFormat).format("MMMM, YYYY")}</span>
        </div>
    } else {
        content = <ul>
            {props.events.map(event => <Event
                key={event.id}
                event={event}
                currentDate={currentDate}
                onEventClick={props.onEventClick}
                handleDelete={props.handleDeleteEvent}
            />)}
        </ul>
    }

    return (
        <div className={styles.container}>
            <div className={contentContainerClassName}>
                <CSSTransition
            appear
            in={props.aboveTheFold}
            timeout={500}
            classNames={transitionClassNames}
          >
                    <div className={contentTransitionClassName} id={"events-container"}>
                        <div className={styles.listControls}>
                            <button onClick={prevMonth}>
                                <FontAwesome prefix={"fas"} name={"fa-chevron-left"} /> Prev
                                Month
                            </button>
                            |{" "}
                            <button onClick={nextMonth}>
                                Next Month{" "}
                                <FontAwesome prefix={"fas"} name={"fa-chevron-right"} />
                            </button>
                        </div>
                        {content}
                        <div className={styles.viewMoreButton}>
                            {props.viewMoreButton}
                        </div>
                    </div>
                </CSSTransition>
            </div>

            <div className={contentContainerClassName}>
                <CSSTransition
            appear
            in={props.aboveTheFold}
            timeout={500}
            classNames={transitionClassNames}
          >
                    <div className={contentTransitionClassName}>
                        <div className={styles.controls}>
                            <button
                  aria-label={"Go to previous month"}
                  onClick={prevMonth}
                  style={{ transform: "rotate(180deg)" }}
                >
                                <FlatIcon name={"flaticon-next"} ariaHidden />
                            </button>
                            <span>
                                {moment(currentMonth, currentMonthFormat).format("MMMM YYYY")}
                            </span>
                            <button aria-label={"Go to next month"} onClick={nextMonth}>
                                <FlatIcon name={"flaticon-next"} ariaHidden />
                            </button>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    {weekdaysShort.map(day => (
                                        <th className={"notranslate"} key={day}>
                                            {t(day)}
                                        </th>
                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((d, i) => {
                    return <tr key={i}>{d}</tr>;
                  })}
                            </tbody>
                        </table>
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
}
