import terraceStyles from "./styles/Terrace.module.scss";
import FontAwesome from "../../utilities/FontAwesome";
import React, {useContext} from "react";
import {StoreContext} from "../../../stores/StoreLoader";
import classNames from 'classnames';
import {
    IReactiveCourseWorkContentItem,
    IReactiveEventContentItem, IReactiveFolderContentItem, IReactiveMediaContentItem,
    IReactiveNewsContentItem
} from "../../../stores/SchoolFeedStore";

export function EditButton(props: {
    contentItem: IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem | IReactiveMediaContentItem | IReactiveFolderContentItem,
    className?: string,
    handleDelete: (contentItem: ContentItem) => void,
}) {
    const {sidebarStore} = useContext(StoreContext);

    const editButtonClassName = classNames({
        [terraceStyles.editButton]: true,
        [props.className || ""]: props.className,
    })

    return <button aria-label={"Edit Item"} className={editButtonClassName} onClick={async e => {
            e.stopPropagation();
            await sidebarStore.setSidebar({
                view: "SchoolFeedPost",
                contentItem: props.contentItem,
                handleDelete: props.handleDelete,
            })
        }}>
            <FontAwesome ariaHidden prefix={'fas'} name={'fa-pencil-alt'} />
        </button>
}
