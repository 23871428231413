import {sourceTypes} from "./SourceUtils";
import {organizationStore} from "../stores/StoreLoader";
import moment from 'moment-timezone';
import {Moment} from 'moment';

export function getVideoElementTypeFromItemType(itemType): "video" | "iframe" {
    return itemType.includes('instagram') || itemType.includes('twitter') ? 'video' : 'iframe';
}

export function debounce(func: any, wait: number, immediate?: boolean) {
    var timeout;

    return function executedFunction() {
        var context = this;
        var args = arguments;

        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

export const createHoursAvailableArray = obj => {
    // this accepts an "hours available" object and outputs a JsonLD formatted hours array
    const dayMap = {
        mon: 'Monday',
        tue: 'Tuesday',
        wed: 'Wednesday',
        thu: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',
        sun: 'Sunday',
    };

    const hours: Array<{
        "@type": string,
        "dayOfWeek": string,
        "opens": string,
        "closes": string,
    }> = [];

    for (const key in obj) {
        // each entry looks like day_1_open
        // don't look at any close values; instead, for each open, we find the matching close time
        if (obj.hasOwnProperty(key) && !key.includes('close')) {
            hours.push({
                "@type": "OpeningHoursSpecification",
                'dayOfWeek': `http://schema.org/${dayMap[key.slice(0, 3)]}`,
                'opens': obj[key],
                'closes': obj[`${key.slice(0, 6)}close`] || '24:00',
            })
        }
    }

    return hours;
};

export function getFormattedEventData(itemObj, dayFormat = "dddd, MMMM D YYYY", timeFormat = "h:mm a") {
    let startTime = moment(itemObj.json_data.event.start),
        endTime: null | Moment = null,
        day = startTime.format("D"),
        dayStr = startTime.format(dayFormat),
        timeStr = "";

    if (itemObj.json_data.event.end) {
        endTime = moment(itemObj.json_data.event.end);
        const end_hour = endTime.hour()
        const end_minutes = endTime.minutes()
        const end_seconds = endTime.seconds()
        const end_milliseconds = endTime.milliseconds()
        if (end_hour === 0 && end_minutes === 0 && end_seconds === 0 && end_milliseconds === 0){
            endTime.subtract(1,"millisecond")
        }
    }

    const startTimeIsValid = moment(startTime).isValid();
    const endTimeIsValid = moment(endTime).isValid();

    if (startTimeIsValid && itemObj.json_data.event?.multi_day === true && endTime !== null && endTimeIsValid) {
        day = startTime.format("D") + "-" + endTime.format("D");
        timeFormat = "ddd, MMM Do Y [at] " + timeFormat;
        dayStr += " - " + moment(endTime).format(dayFormat);
    }

    if (itemObj.json_data.event.allDay === true || itemObj.json_data.event.all_day) {
        timeStr = 'All Day';
    } else if (startTimeIsValid) {
        timeStr = startTime.format(timeFormat);
        if (endTime !== null && endTimeIsValid) {
            timeStr += " - " + endTime.format(timeFormat);
        }
    }

    return {
        startTime,
        endTime,
        day,
        timeStr,
        dayStr,
    }
}

export async function isValidImage(src) {
    return new Promise((res, rej) => {
        const img = new Image();
        img.onerror = () => res(false);
        img.onload = () => res(true);
        img.src = src;
    });
}

export function getLocalTimezone() {
    return moment(Date.now()).tz(moment.tz.guess());
}

export function getTimezoneAbbr(): string {
    // @ts-ignore
    return moment.tz.zone(moment.tz.guess()).abbr(Date.now());
}
